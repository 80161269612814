import { default as indexwVKccFmgv0Meta } from "/var/www/insylio/front/pages/calendar/index.vue?macro=true";
import { default as indexrKriXMmLvEMeta } from "/var/www/insylio/front/pages/calendar/month/index.vue?macro=true";
import { default as indexdOLReaJvYDMeta } from "/var/www/insylio/front/pages/calendar/today/index.vue?macro=true";
import { default as indexELHHPEoFr7Meta } from "/var/www/insylio/front/pages/calendar/week/index.vue?macro=true";
import { default as indexUARINYU3yZMeta } from "/var/www/insylio/front/pages/index.vue?macro=true";
import { default as indexubkK4XNBrLMeta } from "/var/www/insylio/front/pages/login/index.vue?macro=true";
import { default as index8GSD8jkocHMeta } from "/var/www/insylio/front/pages/register/index.vue?macro=true";
import { default as indexmJ6SrIRGDZMeta } from "/var/www/insylio/front/pages/remind-password/[token]/index.vue?macro=true";
import { default as indexftqV2lMaBUMeta } from "/var/www/insylio/front/pages/remind-password/index.vue?macro=true";
import { default as index9ImZsgAqt8Meta } from "/var/www/insylio/front/pages/verify-code/index.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    meta: indexwVKccFmgv0Meta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-month",
    path: "/calendar/month",
    meta: indexrKriXMmLvEMeta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/month/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-today",
    path: "/calendar/today",
    meta: indexdOLReaJvYDMeta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/today/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-week",
    path: "/calendar/week",
    meta: indexELHHPEoFr7Meta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/week/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexUARINYU3yZMeta || {},
    component: () => import("/var/www/insylio/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexubkK4XNBrLMeta || {},
    component: () => import("/var/www/insylio/front/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: index8GSD8jkocHMeta || {},
    component: () => import("/var/www/insylio/front/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password-token",
    path: "/remind-password/:token()",
    meta: indexmJ6SrIRGDZMeta || {},
    component: () => import("/var/www/insylio/front/pages/remind-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password",
    path: "/remind-password",
    meta: indexftqV2lMaBUMeta || {},
    component: () => import("/var/www/insylio/front/pages/remind-password/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-code",
    path: "/verify-code",
    meta: index9ImZsgAqt8Meta || {},
    component: () => import("/var/www/insylio/front/pages/verify-code/index.vue").then(m => m.default || m)
  }
]