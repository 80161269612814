<script setup lang="ts">
useHead({
  title: 'Insylio',
});
</script>

<template>
  <NuxtErrorBoundary>
    <NuxtLayout class="font-inter">
      <NuxtPage />
      <PrimeToast position="bottom-right" />
    </NuxtLayout>
  </NuxtErrorBoundary>
</template>
